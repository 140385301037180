// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Logo.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Logo.tsx");
  import.meta.hot.lastModified = "1716452662854.4663";
}
// REMIX HMR END

import React from "react";
import { Link } from "@remix-run/react";
export default function Logo({
  src,
  children
}) {
  return <Link to="/" aria-label="Back to homepage" className="flex items-center p-2">
      {src && <img src={src} alt="logo" width={45} height={45} />}
      <div className="ml-2">{children}</div>
    </Link>;
}
_c = Logo;
var _c;
$RefreshReg$(_c, "Logo");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;