// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Footer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Footer.tsx");
  import.meta.hot.lastModified = "1716452662850.4663";
}
// REMIX HMR END

import { Link } from "@remix-run/react";
import Logo from "./Logo";
import { useLocation } from "@remix-run/react";
import { CgWebsite } from "react-icons/cg/index.js";
import { FaDiscord } from "react-icons/fa/index.js";
import { AiFillTwitterCircle, AiFillYoutube } from "react-icons/ai/index.js";
function FooterLink({
  url,
  text
}) {
  _s();
  const {
    pathname
  } = useLocation();
  return <li className="flex">
      <Link to={url} prefetch="intent" className={`hover:text-violet-400 ${pathname === url && "text-violet-400 border-violet-400"}}`}>
        {text}
      </Link>
    </li>;
}
_s(FooterLink, "qVMqkCpYCjknUqSjfMln5RFSkbo=", false, function () {
  return [useLocation];
});
_c = FooterLink;
function CategoryLink({
  attributes
}) {
  return <li className="flex">
      <Link to={`/blog/${attributes.slug}`} prefetch="intent" className="hover:text-violet-400">
        {attributes.name}
      </Link>
    </li>;
}
_c2 = CategoryLink;
function RenderSocialIcon({
  social
}) {
  switch (social) {
    case "WEBSITE":
      return <CgWebsite />;
    case "TWITTER":
      return <AiFillTwitterCircle />;
    case "YOUTUBE":
      return <AiFillYoutube />;
    case "DISCORD":
      return <FaDiscord />;
    default:
      return null;
  }
}
_c3 = RenderSocialIcon;
export default function Footer({
  logoUrl,
  logoText,
  menuLinks,
  categoryLinks,
  legalLinks,
  socialLinks
}) {
  return <footer className="py-6 bg-gray-900 text-gray-50">
      <div className="container px-6 mx-auto space-y-6 divide-y divide-gray-400 md:space-y-12 divide-opacity-50">
        <div className="grid grid-cols-12">
          <div className="pb-6 col-span-full md:pb-0 md:col-span-6">
            <Logo src={logoUrl}>
              {logoText && <h2 className="text-2xl font-bold">{logoText}</h2>}
            </Logo>
          </div>

          <div className="col-span-6 text-center md:text-left md:col-span-3">
            <p className="pb-1 text-lg font-medium">Categories</p>
            <ul>
              {categoryLinks.map(link => <CategoryLink key={link.id} {...link} />)}
            </ul>
          </div>

          <div className="col-span-6 text-center md:text-left md:col-span-3">
            <p className="pb-1 text-lg font-medium">Menu</p>
            <ul>
              {menuLinks.map(link => <FooterLink key={link.id} {...link} />)}
            </ul>
          </div>
        </div>
        <div className="grid justify-center pt-6 lg:justify-between">
          <div className="flex">
            <span className="mr-2">
              ©{new Date().getFullYear()} All rights reserved
            </span>
            <ul className="flex">
              {legalLinks.map(link => <Link to={link.url} className="text-gray-400 hover:text-gray-300 mr-2" key={link.id}>
                  {link.text}
                </Link>)}
            </ul>
          </div>
          <div className="flex justify-center pt-4 space-x-4 lg:pt-0 lg:col-end-13">
            {socialLinks.map(link => {
            return <a key={link.id} rel="noopener noreferrer" href={link.url} title={link.text} target={link.newTab ? "_blank" : "_self"} className="flex items-center justify-center w-10 h-10 rounded-full bg-violet-400 text-gray-900">
                  <RenderSocialIcon social={link.social} />
                </a>;
          })}
          </div>
        </div>
      </div>
    </footer>;
}
_c4 = Footer;
var _c, _c2, _c3, _c4;
$RefreshReg$(_c, "FooterLink");
$RefreshReg$(_c2, "CategoryLink");
$RefreshReg$(_c3, "RenderSocialIcon");
$RefreshReg$(_c4, "Footer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;