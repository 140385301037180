// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Navbar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Navbar.tsx");
  import.meta.hot.lastModified = "1716452662854.4663";
}
// REMIX HMR END

import Logo from "./Logo";
import { Link, useLocation } from "@remix-run/react";
import { Avatar } from "~/components/Avatar";
import { Logout } from "~/routes/logout";
function NavLink({
  url,
  text
}) {
  _s();
  const {
    pathname
  } = useLocation();
  return <li className="flex">
      <Link to={url} className={`flex items-center mx-4 -mb-1 border-b-2 border-transparent ${pathname === url && "text-violet-400 border-violet-400"}}`}>
        {text}
      </Link>
    </li>;
}
_s(NavLink, "qVMqkCpYCjknUqSjfMln5RFSkbo=", false, function () {
  return [useLocation];
});
_c = NavLink;
export default function Navbar({
  links,
  logoUrl,
  logoText,
  user
}) {
  return <div className="p-4 bg-gray-900 text-gray-100">
      <div className="container flex justify-between h-16 mx-auto px-0 sm:px-6">
        <Logo src={logoUrl}>
          {logoText && <h2 className="text-2xl font-bold">{logoText}</h2>}
        </Logo>

        <div className="items-center flex-shrink-0 hidden lg:flex">
          <ul className="items-stretch hidden space-x-3 lg:flex">
            {links.map(item => <NavLink key={item.id} {...item} />)}
            {user ? <div className="flex items-center gap-2">
                <Avatar user={user} />
                <Logout />
              </div> : <Link to="/login">Login</Link>}
          </ul>
        </div>

        <button className="p-4 lg:hidden">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 text-gray-100">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>
    </div>;
}
_c2 = Navbar;
var _c, _c2;
$RefreshReg$(_c, "NavLink");
$RefreshReg$(_c2, "Navbar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;