// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Banner.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Banner.tsx");
  import.meta.hot.lastModified = "1716452662850.4663";
}
// REMIX HMR END

import classNames from "classnames";
function colors(type) {
  switch (type) {
    case "info":
      return "bg-violet-400";
    case "warning":
      return "bg-yellow-500";
    case "alert":
      return "bg-pink-500";
    default:
      return "bg-gray-900";
  }
}
export default function Banner({
  data
}) {
  if (!data) return null;
  const {
    heading,
    text,
    type,
    link
  } = data;
  return <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
      <div className={classNames("pointer-events-auto flex items-center justify-between gap-x-6 py-2.5 px-6 sm:rounded-xl sm:py-3 sm:pr-3.5 sm:pl-4", colors(type))}>
        <p className="text-sm leading-6 text-white">
          <a href={link.url} target={link.newTab ? "_blank" : "_self"}>
            <strong className="font-semibold">{heading}</strong> {text}&nbsp;
            <span aria-hidden="true">&rarr;</span>
          </a>
        </p>
      </div>
    </div>;
}
_c = Banner;
var _c;
$RefreshReg$(_c, "Banner");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;