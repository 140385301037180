// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import stylesheet from "~/tailwind.css";
import { json } from "@remix-run/node";
import { getStrapiMedia } from "./utils/api-helpers";
import { fetchStrapiData } from "~/api/fetch-strapi-data.server";
import { userme } from "./api/auth/userme.server";
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, isRouteErrorResponse, useRouteError, useLoaderData } from "@remix-run/react";
import Navbar from "~/components/Navbar";
import Footer from "~/components/Footer";
import Banner from "~/components/Banner";
export const links = () => [{
  rel: "stylesheet",
  href: stylesheet
}];
export async function loader({
  request
}) {
  const path = `/global`;
  const urlParamsObject = {
    populate: ["metadata.shareImage", "favicon", "notificationBanner.link", "navbar.links", "navbar.navbarLogo.logoImg", "footer.footerLogo.logoImg", "footer.menuLinks", "footer.legalLinks", "footer.socialLinks", "footer.categories"]
  };
  const response = await fetchStrapiData(path, urlParamsObject);
  const user = await userme(request);
  return json({
    ...response,
    ENV: global.ENV,
    user: user || null
  });
}
export function ErrorBoundary() {
  _s();
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    return <div>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </div>;
  } else if (error instanceof Error) {
    return <div>
        <h1>Error</h1>
        <p>{error.message}</p>
        <p>The stack trace is:</p>
        <pre>{error.stack}</pre>
      </div>;
  } else {
    return <h1>Unknown Error</h1>;
  }
}
_s(ErrorBoundary, "oAgjgbJzsRXlB89+MoVumxMQqKM=", false, function () {
  return [useRouteError];
});
_c = ErrorBoundary;
export default function App() {
  _s2();
  const data = useLoaderData();
  const {
    notificationBanner,
    navbar,
    footer
  } = data.data.attributes;
  const navbarLogoUrl = getStrapiMedia(navbar.navbarLogo.logoImg.data.attributes.url);
  const footerLogoUrl = getStrapiMedia(footer.footerLogo.logoImg.data.attributes.url);
  return <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>

      <body className="bg-gray-900 min-h-screen">
        <Navbar links={navbar.links} logoUrl={navbarLogoUrl} logoText={navbar.navbarLogo.logoText} user={data.user} />

        <Outlet />
        <Footer logoUrl={footerLogoUrl} logoText={footer.footerLogo.logoText} menuLinks={footer.menuLinks} categoryLinks={footer.categories.data} legalLinks={footer.legalLinks} socialLinks={footer.socialLinks} />

        <Banner data={notificationBanner} />
        <script dangerouslySetInnerHTML={{
        __html: `window.ENV = ${JSON.stringify(data.ENV)}`
      }} />


        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>;
}
_s2(App, "5thj+e1edPyRpKif1JmVRC6KArE=", false, function () {
  return [useLoaderData];
});
_c2 = App;
var _c, _c2;
$RefreshReg$(_c, "ErrorBoundary");
$RefreshReg$(_c2, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;