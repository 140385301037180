// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Avatar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Avatar.tsx");
  import.meta.hot.lastModified = "1716452662850.4663";
}
// REMIX HMR END

import { getStrapiMedia } from "~/utils/api-helpers";
import { Link } from "@remix-run/react";
export const Avatar = ({
  user
}) => {
  const imageUrl = getStrapiMedia(user.image?.url) || "https://robohash.org/mail@ashallendesign.co.uk";
  return <div className="flex mt-auto items-center justify-between">
      <div className="flex items-center">
        <img className="h-8 w-8 mr-3 rounded-full" src={imageUrl} alt={user?.image?.alternativeText || "User Avatar"} />
        <h5 className="leading-none font-semibold text-gray-100 hover:text-violet-400">
          <Link to="profile">{user.username}</Link>
        </h5>
      </div>
    </div>;
};
_c = Avatar;
var _c;
$RefreshReg$(_c, "Avatar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;