// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/logout.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/logout.tsx");
  import.meta.hot.lastModified = "1716452662854.4663";
}
// REMIX HMR END

import { redirect } from "@remix-run/node";
import { Form } from "@remix-run/react";
import { logout } from "~/utils/session.server";
export const action = async ({
  request
}) => {
  return logout(request);
};
export const loader = async () => {
  return redirect("/");
};
export function Logout() {
  return <div className="hidden md:flex md:items-center md:space-x-6">
      <Form method="post" action="/logout">
        <button type="submit">
          <span className="cursor-pointer hover:text-fuchsia-400">Logout</span> &nbsp;
          <span aria-hidden="true">&rarr;</span>
        </button>
      </Form>
    </div>;
}
_c = Logout;
var _c;
$RefreshReg$(_c, "Logout");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;